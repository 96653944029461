import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button, Table, Row } from 'reactstrap';
import { AppContext } from './AppContext';
import {Tabla} from './Tabla';
import {Trabajador} from './Trabajador';
import {Consulta} from './Consulta';
import Swal from 'sweetalert2';

export const Usuarios = () =>{
    const edoGlobal = useContext(AppContext);
    let [objetos,setObjetos] = useState([]);
    let [supervisores,setSupervisores] = useState([]);
    let [idObj,setIdObj] = useState(0);
    let [nombre,setNombre] = useState("");
    let [email,setEmail] = useState("");
    let [pwdA,setPwdA] = useState("");
    let [pwdB,setPwdB] = useState("");
    let [activo,setActivo] = useState(false);
    let [ejecutivo,setEjecutivo] = useState(false);
    let [supervisor,setSupervisor] = useState(false);
    let [idSupervisor,setIdSupervisor] = useState(0);
    let [timbrar,setTimbrar] = useState(false);
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);
    const [consultando,setConsultando] = useState(false);
    
    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/home/usuarios`;
            let resp = await fetch(url);
            let usuarios = await resp.json();
            setObjetos(usuarios);
            url = `${edoGlobal.urlws}/home/supervisores`;
            resp = await fetch(url);
            objetos = await resp.json();
            setSupervisores(objetos);
            setCargando(false);
        })();
    }, []);

    async function cargarObjetos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/home/usuarios`;
        let resp = await fetch(url);
        let objetos = await resp.json();
        setObjetos(objetos);
        url = `${edoGlobal.urlws}/home/supervisores`;
        resp = await fetch(url);
        objetos = await resp.json();
        setCargando(false);
        setSupervisores(objetos);
    }
    const nuevoObjeto = () =>{
        setIdObj(0);
        setNombre("");
        setEmail("");
        setPwdA("");
        setPwdB("");
        setEjecutivo(false);
        setSupervisor(false);
        setIdSupervisor(0);
        setActivo(true);
        setTimbrar(false);    
        setEditando(true);
    }
    const editar = async(i) =>{
        setCargando(true);
        let url = `${edoGlobal.urlws}/home/cargarUsuario/${objetos[i].Id}`;
        let resp = await fetch(url);
        const u = await resp.json();
        setCargando(false);
        setIdObj(u.Id);
        setNombre(u.Nombre);
        setEmail(u.Email);
        setPwdA(u.Pwd);
        setPwdB(u.Pwd);
        setActivo(u.Activo);
        setEjecutivo(u.Ejecutivo);
        setSupervisor(u.Supervisor);
        setIdSupervisor(u.IdSupervisor);
        setTimbrar(u.Timbrar);
        setEditando(true);
    }
    const eliminar = i =>{
        //setIdObj(objetos[i].R);
    }
    const guardar = async() => {
        if(nombre == "" || email == "" || pwdA == "" ){
            Swal.fire('Atencion','Nombre, Email y Contraseña son obligatorios','error');
            return;
        }
        if(pwdA !== pwdB ){
            Swal.fire('Atencion','No coincide la contraseña','error');
            return;
        }
        const dato = {
            Id : idObj,
            Nombre : nombre,
            Email : email,
            Pwd : pwdA,
            Activo : activo,
            Ejecutivo : ejecutivo, Supervisor : supervisor, 
            IdSupervisor : idSupervisor, Timbrar : timbrar
        }
        let url = `${edoGlobal.urlws}/home/wsguardarUsuario`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            cargarObjetos();
            setEditando(false);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    const estilo1 = { display: editando || consultando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    const usu = edoGlobal.hasOwnProperty('usuario') ? edoGlobal.usuario.Id : 0;
    return(
        <div>
            <div style={estilo2}>
                <div>
                    <Row>
                        <div className='col'>
                            <FormGroup  >
                                <span>Nombre</span>
                                <Input size="sm" type="text" value={nombre} onChange={e => setNombre(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup  >
                                <span>Email</span>
                                <Input size="sm" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col-2'>
                            <FormGroup  >
                                <span>Contraseña</span>
                                <Input size="sm" type="password" value={pwdA} onChange={e => setPwdA(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col-2'>
                            <FormGroup  >
                                <span>Confirmar contraseña</span>
                                <Input size="sm" type="password" value={pwdB} onChange={e => setPwdB(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col-1'>
                            <FormGroup  >
                                <span>Activo<br/></span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={activo} onChange={e => setActivo(e.target.checked)} />
                            </FormGroup>
                        </div>
                        <div className='col-1'>
                            <FormGroup  >
                                <span>Es Supervisor<br/></span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={supervisor} onChange={e => setSupervisor(e.target.checked)} />
                            </FormGroup>
                        </div>
                        <div className='col-1'>
                            <FormGroup  >
                                <span>Es Ejecutivo<br/></span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={ejecutivo} onChange={e => setEjecutivo(e.target.checked)} />
                            </FormGroup>
                        </div>
                        <div className='col-2'>
                            <FormGroup  >
                                <span>Supervisor<br/></span>
                                <Input size="sm" type="select" value={idSupervisor} onChange={e => setIdSupervisor(e.target.value)}>
                                    <option value={0}>No especificado</option>
                                    {supervisores.map((o,i)=><option key={i} value={o.Id}>{o.Nombre}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className='col-2'>
                            <FormGroup  >
                                <span>Timbrar<br/></span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={timbrar} onChange={e => setTimbrar(e.target.checked)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <Button size="sm" color="success" onClick={guardar}>Guardar</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button size="sm" color="danger" onClick={() => setEditando(false)}>Cancelar</Button>
                        </div>
                    </Row>
                </div>
            </div>
            <Container style={estilo1}>
                <div className="row">
                    <div className="col-1">
                        <br />
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <Button outline color="success" size="sm" onClick={nuevoObjeto}><img src="imagenes/add.png" alt="nuevo" /></Button>
                    </div>
                </div>
                <Table size="sm" striped hover>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th className='celdaCh'>Activo</th>
                            <th className='celdaCh'></th>
                            <th className='celdaCh'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {objetos.map((o,i)=>
                            <tr key={i}>
                                <td>{o.Nombre}</td>
                                <td><input type="checkbox" checked={o.Activo} /></td>
                                <td><img src="/imagenes/edit.png" alt="editar" title="editar" onClick={()=>editar(i)} /></td>
                                <td><img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={()=>eliminar(i)} /></td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Container>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }
        </div>
    )
}