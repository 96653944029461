import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Input, Table, Modal, Button, InputGroup, InputGroupText, ModalBody, Spinner, ModalFooter, Badge, Container, Row} from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { Panel } from './Panel';

export const Creditos = (props) => {
    const edoGlobal = useContext(AppContext);
    let [creditos, setCreditos] = useState([]);
    let [credito, setCredito] = useState("");
    let [enDetalle, setEnDetalle] = useState(false);
    let [fecha,setFecha] = useState(new Date().yyyymmdd('-'));
    let [fechaPago,setFechaPago] = useState(new Date().yyyymmdd('-'));
    let [monto, setMonto] = useState(182639.40);
    let [meses, setMeses] = useState(36);
    let [amortizaciones, setAmortizaciones] = useState(36);
    let [tasa, setTasa] = useState(25);
    let [mesesPlazo, setMesesPlazo] = useState([12,24,36,48,60,72]);
    let [periodicidades, setPeriodicidades] = useState([{I:'M',N:'Mensual'},{I:'Q',N:'Quincenal'},{I:'S',N:'Semanal'}]);
    let [periodicidad, setPeriodicidad] = useState('M');
    let [corrida, setCorrida] = useState({Mensualidades:[], MensualidadesMora:[], Pagos:[], TotPerActual:0,TotalMora:0, TotPerActualPag:0,TotalMoraPag:0});
    let [cargando, setCargando] = useState(false);
    let [conMora, setConMora] = useState(false);
    let [conApp, setConApp] = useState(false);
    let [aplicacion, setAplicacion] = useState([]);
    let [pago, setPago] = useState(0);
    let [pagadas, setPagadas] = useState(0);
    let [clientes,setClientes] = useState([]);
    let [cliente, setCliente] = useState(0);
    let [generada, setgenerada] = useState(false);
    let [parcialidad, setParcialidad] = useState(0);
    let [parcialidades, setParcialidades] = useState([]);
    let [plazoMonto, setPlazoMonto] = useState('X');
    let [nuevo, setNuevo] = useState(false);

    useEffect(() => {
        cargarCatalogos();
        cargarCreditos();
    }, []);

    async function cargarCatalogos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/agenda/clientes`;
        let resp = await fetch(url);
        let datos = await resp.json();
        setClientes(datos);
        setCargando(false);
    }
    async function cargarCreditos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/credito/creditos/${edoGlobal.usuario.Sucursal}`;
        let resp = await fetch(url);
        let datos = await resp.json();
        setCreditos(datos);
        setCargando(false);
    }
    function imprimirAmortizaciones(){
        const url = `${edoGlobal.urlws}/credito/amortizacionesPDF?sucursal=${edoGlobal.usuario.Sucursal}&credito=${credito}`;
        window.open(url, '', '');
    }
    function imprimirPagos(){
        const url = `${edoGlobal.urlws}/credito/pagosPDF?sucursal=${edoGlobal.usuario.Sucursal}&credito=${credito}`;
        window.open(url, '', '');
    }
    function imprimirPago(p){
        const url = `${edoGlobal.urlws}/credito/pagoPDF?sucursal=${edoGlobal.usuario.Sucursal}&credito=${credito}&pago=${p}`;
        window.open(url, '', '');
    }
    function detalle(i){
        credito = creditos[i].NoCredito;
        setCredito(creditos[i].NoCredito)
        cargarCredito()
    }
    function actualizaMeses(m){
        let a = m;
        if(periodicidad == 'Q')
           a = a * 2;
        if(periodicidad == 'S')
           a = a * 4;
        setMeses(m);
        setAmortizaciones(a);
    }
    function actualizaPeriodicidad(p){
        let a = meses;
        if(p == 'Q')
           a = a * 2;
        if(p == 'S')
           a = a * 4;
        setPeriodicidad(p);
        setAmortizaciones(a);
    }
    function aplicarPlazoMonto(v){
        plazoMonto = v;
        setPlazoMonto(v);
        cargarCredito();
    }
    function aplicarMonto(){
        cargarCredito();
    }
    async function cargarCreditoNormal(){
        parcialidad = 0;
        setParcialidad(0);
        cargarCredito();
    }
    function regresar(){
        setEnDetalle(false);
        setNuevo(false);
    }
    function nuevoCredito(){
        setCorrida({Mensualidades:[], MensualidadesMora:[], Pagos:[], TotPerActual:0,TotalMora:0, TotPerActualPag:0,TotalMoraPag:0});
        setNuevo(true);
        setEnDetalle(true);
    }
    async function cargarCredito(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/credito/cargar?sucursal=${edoGlobal.usuario.Sucursal}&credito=${credito}&fechaPago=${fechaPago}&parcialidad=${parcialidad}&monto=${pago}&plazoMonto=${plazoMonto}`;
        let resp = await fetch(url);
        let res = await resp.json();
        res.TotPerActualPag = res.TotPerActual;
        res.TotalMoraPag = res.TotalMora;
        console.log("credito",res);
        setMonto(res.Monto);
        setTasa(res.Tasa);
        setPlazoMonto(res.Mensualidades.length);
        setFecha(res.FechaStr);
        setParcialidades(res.Mensualidades.map((o, i) => o.Numero));
        setConApp(false);
        setConMora(true);
        setCargando(false);
        setCorrida(res);
        setEnDetalle(true);
    }
    async function guardarPago(){
        let importe = Number(pago);
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Guardar el pago por " + importe.formato(2) + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            guardarPagoB(importe);
          })        
    }
    async function guardarPagoB(importe){
        let url = `${edoGlobal.urlws}/credito/pagar?usuario=${edoGlobal.usuario.Id}&sucursal=${edoGlobal.usuario.Sucursal}&credito=${credito}&fechaPago=${fechaPago}&importe=${importe}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if(res == "ok")
           Swal.fire('Atencion','se guardo el pago','success');
        else
           Swal.fire('Atencion',res,'error');
    }
    async function guardar(){
        let url = `${edoGlobal.urlws}/credito/guardar?usuario=${edoGlobal.usuario.Id}&cliente=${cliente}&monto=${monto}&meses=${meses}&tasa=${tasa}&fechaPrimerPago=${fecha}&pagadas=${pagadas}&fechaPago=`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
    }

    async function cargar(){
        let url = `${edoGlobal.urlws}/credito/corrida?monto=${monto}&meses=${meses}&parcialidades=${amortizaciones}&tasa=${tasa}&periodicidad=${periodicidad}&fechaPrimerPago=${fecha}&pagadas=${pagadas}&fechaPago=`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.json();
        res.TotPerActualPag = res.TotPerActual;
        res.TotalMoraPag = res.TotalMora;
        if(cliente != 0)
           setgenerada(true);
        setConApp(false);
        setConMora(false);
        setCargando(false);
        setCorrida(res);
    }
    async function cargarMora(){
        let url = `${edoGlobal.urlws}/credito/corrida?monto=${monto}&meses=${meses}&tasa=${tasa}&fechaPrimerPago=${fecha}&fechaPago=${fechaPago}&pagadas=${pagadas}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.json();
        res.TotPerActualPag = res.TotPerActual;
        res.TotalMoraPag = res.TotalMora;
        setConApp(false);
        setConMora(true);
        setCargando(false);
        setCorrida(res);
    }
    function aplicar(){
        let imp = 0;
        let st1 = 0;
        let st2 = 0;
        let importe = Number(pago);
        corrida.TotPerActualPag = corrida.TotPerActual;
        corrida.TotalMoraPag = corrida.TotalMora - importe;
        aplicacion = corrida.MensualidadesMora.map((o) => {
            return {
                FechaStr : o.FechaStr,
                Capital : o.Capital,
                DiasMora : o.DiasMora,
                InteresMoratorio : o.InteresMoratorio,
                IvaMoratorio : o.IvaMoratorio,
                Interes : o.Interes,
                Iva : o.Iva,
                Total : o.Total,
                Abono : o.Total
            }
        });
        for(let i = 0; i < aplicacion.length; i++){
            aplicacion[i].Abono = 0.0;
            imp = aplicacion[i].InteresMoratorio + aplicacion[i].IvaMoratorio;
            if(importe > imp){
                importe -= imp;
                aplicacion[i].Abono += imp;
                aplicacion[i].InteresMoratorio = 0;                
                aplicacion[i].IvaMoratorio = 0;
            }
            else{
                st1 = importe / 1.16;
                st2 = importe - st1;
                aplicacion[i].Abono += importe;
                aplicacion[i].InteresMoratorio -= st1;
                aplicacion[i].IvaMoratorio -= st2;
                importe = 0;
            }

            imp = aplicacion[i].Interes + aplicacion[i].Iva;
            if(importe > imp){
                importe -= imp;
                aplicacion[i].Abono += imp;
                aplicacion[i].Interes = 0;                
                aplicacion[i].Iva = 0;
            }
            else{
                st1 = importe / 1.16;
                st2 = importe - st1;
                aplicacion[i].Abono += importe;
                aplicacion[i].Interes -= st1;
                aplicacion[i].Iva -= st2;
                importe = 0;
            }
            if(importe > aplicacion[i].Capital){
                importe -= aplicacion[i].Capital;
                aplicacion[i].Abono += aplicacion[i].Capital;
                aplicacion[i].Capital = 0;
            }
            else{
                aplicacion[i].Abono += importe;
                aplicacion[i].Capital -= importe;
                importe = 0;
            }
        }
        corrida.TotPerActualPag = importe;
        /*
        if(importe > corrida.TotPerActualPag){
            importe -= corrida.TotPerActualPag;
            corrida.TotPerActualPag = 0;
        }
        else{
            corrida.TotPerActualPag -= importe;
            importe = 0;
        }
        */
        setAplicacion(aplicacion);
        setConApp(true);
    }
    const estilo = { display: conMora ? 'inline' : 'none' };
    const estiloApp = { display: conApp ? 'inline' : 'none' };
    const estiloTbl = { display: enDetalle ? 'none' : 'inline' };
    const estiloDet = { display: enDetalle ? 'inline' : 'none' };
    const estiloNoNuevo = { display: !nuevo ? 'inline' : 'none' };
    const estiloNuevo = { display: nuevo ? 'inline' : 'none' };
    return(
        <div>
            <div style={estiloTbl}>
                <Table size="sm" striped hover>
                    <thead>
                        <th className="celdaMed">Credito</th>
                        <th className='celdaMed'>Fecha</th>
                        <th>Cliente</th>
                        <th className='numeroCeldaMed'>Monto</th>
                        <th><img src="/imagenes/add.png" alt="nuevo credito" title="nuevo credito" onClick={nuevoCredito} /></th>
                        <th></th>
                    </thead>
                    <tbody>
                        {creditos.map((o, i) => {
                            return (
                                <tr key={i}>
                                    <td>{o.NoCredito}</td>
                                    <td>{o.Fecha}</td>
                                    <td>{o.Cliente}</td>
                                    <td className='numeroCeldaMed'>{o.Monto.formato(2)}</td>
                                    <td><img src="/imagenes/cotizar.png" alt="detalle" title="detalle" onClick={()=> detalle(i)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div style={estiloDet}>
            <Container>
                {cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="warning" />
                    </div>
                    :
                    null
                }
                    <div style={estiloNoNuevo}>
                        <Row>
                            <div className='col-2'>
                                <span>Monto a financiar</span>
                                <Input size="sm" value={monto} />
                            </div>
                            <div className='col-1'>
                                <span>Plazo</span>
                                <Input size="sm" value={meses} />
                            </div>
                            <div className='col-1'>
                                <span>Tasa</span>
                                <Input size="sm" value={tasa} />
                            </div>
                            <div className='col-2'>
                                <span>Primer pago</span>
                                <Input size="sm" type="date" value={fecha} />
                            </div>
                            <div className='col-2'>
                                <span><br /></span>
                                <Button size="sm" color="danger" outline onClick={regresar} >Regresar</Button>
                            </div>
                        </Row>
                        <Row>
                            <div className='col-2'>
                                <span>Fecha pago</span>
                                <Input size="sm" type="date" value={fechaPago} onChange={(e) => setFechaPago(e.target.value)} />
                            </div>
                            <div className='col-2'>
                                <br />
                                <Button size="sm" color="primary" onClick={cargarCredito} > <span className="info-box-icon bg-info"><i class="fa fa-calculator"></i></span> Mora</Button>
                            </div>
                        </Row>
                    </div>
                    <div style={estiloNuevo}>
                        <Row>
                            <div className='col-2'>
                                <span>Monto a financiar</span>
                                <Input size="sm" value={monto} onChange={(e)=>setMonto(e.target.value)} />
                            </div>
                            <div className='col-1'>
                                <span>Plazo meses</span>
                                <Input size="sm" type="select" value={meses} onChange={e => actualizaMeses(e.target.value)}>
                                        {mesesPlazo.map((o, i) => <option key={i} value={o}>{o}</option>)}
                                </Input>
                            </div>
                            <div className='col-2'>
                                <span>Periodicidad</span>
                                <InputGroup>
                                    <Input size="sm" type="select" value={periodicidad} onChange={e => actualizaPeriodicidad(e.target.value)}>
                                        {periodicidades.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                    <InputGroupText>
                                        {amortizaciones}
                                    </InputGroupText>
                                </InputGroup>
                            </div>
                            <div className='col-1'>
                                <span>Tasa</span>
                                <Input size="sm" value={tasa} onChange={(e)=>setTasa(e.target.value)}/>
                            </div>
                            <div className='col-2'>
                                <span>Primer pago</span>
                                <Input size="sm" type="date" value={fecha} onChange={(e)=>setFecha(e.target.value)}/>
                            </div>
                            <div className='col-2'>
                                <span><br /></span>
                                <Button size="sm" color="danger" outline onClick={regresar} >Regresar</Button>
                            </div>
                        </Row>
                        <Row>
                            <div className='col-2'>
                                <span>Fecha pago</span>
                                <Input size="sm" type="date" value={fechaPago} onChange={(e) => setFechaPago(e.target.value)} />
                            </div>
                            <div className='col-2'>
                                <br />
                                <Button size="sm" color="primary" onClick={cargar} > <span className="info-box-icon bg-info"><i class="fa fa-calculator"></i></span> Calcular</Button>
                            </div>
                        </Row>
                    </div>
                <Row>
                    <div className='col'>
                        <hr/>
                        <h6>Tabla de amortización  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <img src="imagenes/printer.png" alt="pagos" title="imprimir amortizaciones" onClick={imprimirAmortizaciones}/></h6>
                        <Table size="sm" striped hover>
                            <thead>
                                <th className="celdaCh">No.</th>
                                <th className='celdaMed'>Fecha</th>
                                <th className='numeroCeldaMed'>Capital</th>
                                <th className='numeroCeldaMed'>Amortizacion</th>
                                <th className='numeroCeldaMed'>Intereses</th>
                                <th className='numeroCeldaMed'>I.V.A.</th>
                                <th className='numeroCeldaMed'>Pago</th>
                                <th className='numeroCeldaMed'>Pagada</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {corrida.Mensualidades.map((o, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{o.Numero}</td>
                                            <td>{o.FechaStr}</td>
                                            <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Amortizacion.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Interes.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Iva.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Pago.formato(2)}</td>
                                            <td><input type="checkbox" checked={o.Pagada} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className='col'>
                        <div style={estilo}>
                            <hr />
                            <h6>Intereses moratorios</h6>
                            <Table size="sm" striped hover>
                                <thead>
                                    <th className='celdaMed'>Fecha</th>
                                    <th className='numeroCeldaMed'>Capital</th>
                                    <th className='numeroCeldaMed'>Dias</th>
                                    <th className='numeroCeldaMed'>Int.Moratorio</th>
                                    <th className='numeroCeldaMed'>Int.Ordinario</th>
                                    <th className='numeroCeldaMed'>Total</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {corrida.MensualidadesMora.map((o, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{o.FechaStr}</td>
                                                <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                                <td className='numeroCeldaMed'>{o.DiasMora}</td>
                                                <td className='numeroCeldaMed'>{(o.InteresMoratorio + o.IvaMoratorio).formato(2)}</td>
                                                <td className='numeroCeldaMed'>{(o.Interes + o.Iva).formato(2)}</td>
                                                <td className='numeroCeldaMed'>{o.Total.formato(2)}</td>
                                            </tr>
                                        )
                                    })}
                                        <tr>
                                            <td colspan="5">Intereses ordinarios del periodo corriente</td>
                                            <td className='numeroCeldaMed'>{corrida.TotPerActual.formato(2)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td className='numeroCeldaMed'>{corrida.TotalMora.formato(2)}</td>
                                        </tr>
                                </tbody>
                            </Table>
                            <Row>
                                <div className='col-2'>
                                    <span>Pago</span>
                                    <Input size="sm" value={pago} onChange={(e) => setPago(e.target.value)} />
                                </div>
                                <div className='col-2'>
                                    <br />
                                    <Button size="sm" color="primary" onClick={aplicar} > <span className="info-box-icon bg-info"><i class="fa fa-calculator"></i></span> Aplicar</Button>
                                </div>
                                    <div className='col-3'>
                                        <span>Parcialidad</span>
                                        <Input size="sm" type="select" className="form-control" value={parcialidad} onChange={(e) => setParcialidad(e.target.value)}>
                                            <option value={0}>Especifique</option>
                                            {parcialidades.map((o, i) => (
                                                <option key={i} value={o}>
                                                    {o}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className='col-4'>
                                        <span><br /></span>
                                        <Input size="sm" type="select" className="form-control" value={plazoMonto} onChange={(e) => aplicarPlazoMonto(e.target.value)}>
                                            <option value={'X'}>Tipo de aplicacion</option>
                                            <option value={'M'}>Aplicar a monto</option>
                                            <option value={'P'}>Aplicar a plazo</option>
                                        </Input>
                                    </div>
                            </Row>
                            <div style={estiloApp}>
                                <hr />
                                <h6>aplicación</h6>
                                <Table size="sm" striped hover>
                                    <thead>
                                        <th className='celdaMed'>Fecha</th>
                                        <th className='numeroCeldaMed'>Capital</th>
                                        <th className='numeroCeldaMed'>Dias</th>
                                        <th className='numeroCeldaMed'>Int.Moratorio</th>
                                        <th className='numeroCeldaMed'>Int.Ordinario</th>
                                        <th className='numeroCeldaMed'>Total</th>
                                        <th className='numeroCeldaMed'>Aplicado</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        {aplicacion.map((o, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{o.FechaStr}</td>
                                                    <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{o.DiasMora}</td>
                                                    <td className='numeroCeldaMed'>{(o.InteresMoratorio + o.IvaMoratorio).formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{(o.Interes + o.Iva).formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{o.Total.formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{o.Abono.formato(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colspan="5">Excedente aplicado a monto</td>
                                            <td className='numeroCeldaMed'>{corrida.TotPerActualPag.formato(2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <hr/>
                        <h6>Pagos realizados  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <img src="imagenes/printer.png" alt="pagos" title="imprimir pagos" onClick={imprimirPagos}/>  </h6>
                        <Table size="sm" striped hover>
                            <thead>
                                <th className="celdaCh">Pago</th>
                                <th className='celdaMed'>Fecha</th>
                                <th className='numeroCeldaMed'>Importe</th>
                                <th className='numeroCeldaMed'>Capital</th>
                                <th className='numeroCeldaMed'>Int.Ord.</th>
                                <th className='numeroCeldaMed'>I.V.A.</th>
                                <th className='numeroCeldaMed'>Int.Mor.</th>
                                <th className='numeroCeldaMed'>I.V.A.</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {corrida.Pagos.map((o, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{o.Id}</td>
                                            <td>{o.Fecha}</td>
                                            <td className='numeroCeldaMed'>{o.Importe.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Interes.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Iva.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.InteresMoratorio.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.IvaMoratorio.formato(2)}</td>
                                            <td><img src="imagenes/printer.png" onClick={()=> imprimirPago(o.Id)} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                </Row>
            </Container>
            </div>
        </div>
    )
}
