import React from 'react';
import {useState, useEffect} from 'react';
import { Input, Button, Card, CardHeader, CardBody, CardTitle, CardText, Badge, Container } from 'reactstrap';

export const Denominacion = (props) => {
    let [cantidades, setCantidades] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    let [importes, setImportes] = useState([0.5,1,2,5,10,20,20,50,100,200,500,1000]);
    let [monedas, setMonedas] = useState(["$50c","$1","$2","$5","$10","$20"]);
    let [billetes, setBilletes] = useState(["$20","$50","$100","$200","$500","$1,000"]);
    let [total, setTotal] = useState(0);

    useEffect(() => {
        for (let i = 0; i < cantidades.length; i++) 
           cantidades[i] = 0;
        totales();
      }, [props.id]);

    function captura(i,v){
        cantidades[i] = v;
        totales();
    }
    function totales(){
        let tot = 0;
        let n;
        setTotal(0);
        for (let i = 0; i < cantidades.length; i++) {
           n = Number(cantidades[i]);
           tot += n * importes[i];
        }
        setTotal(tot);
        props.actualizaTotales(tot,cantidades);
    }
    return(
        <Container>
            <h6>Monedas</h6>
            <div className='row'>
                {monedas.map((o, i) => <div className='col'>
                    <Card color="secondary" outline key={i} >
                        <CardHeader>
                            <Badge color="secondary">{monedas[i]}</Badge>
                        </CardHeader>
                        <CardBody>
                            <CardText>
                                <Input size="sm" value={cantidades[i]} onChange={(e) => captura(i, e.target.value)} />
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
                )}
            </div>
            <h6>Billetes</h6>
            <div className='row'>
                {billetes.map((o, i) => <div className='col'>
                    <Card color="secondary" outline key={i}>
                        <CardHeader>
                            <Badge color="warning">{billetes[i]}</Badge>
                        </CardHeader>
                        <CardBody>
                            <CardText>
                                <Input size="sm" value={cantidades[i + 6]} onChange={(e) => captura(i + 6, e.target.value)} />
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
                )}
            </div>
            <div className='row'>
                <span><Badge color="success">${total.toFixed(2)}</Badge></span>
            </div>
        </Container>
    )    
}