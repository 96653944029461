import React, { useState, useEffect, useCallback } from 'react';
export const Calendario = (props) => {
    let [meses,setMeses] = useState(["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]);
    let [fecha,setFecha] = useState(new Date());
    let [mes,setMes] = useState(new Date().getMonth());
    let [anio,setAnio] = useState(new Date().getFullYear());
    let [fechaActual,setFechaActual] = useState(new Date());
    let [fechas,setFechas] = useState([]);
    let [semanas,setSemanas] = useState([0,1,2,3,4,5]);
    let [anios,setAnios] = useState([]);
    let [dias,setDias] = useState([0,1,2,3,4,5,6]);
    let [aniosC,setAniosC] = useState([0,1,2,3]);
    let [nivel,setNivel] = useState(1);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        cargar();
        cargarAnios(anio);
    }, []);

    function cargar(){
        let dias = 42;
        let dia = 1;
        let f = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        while (f.getDay() > 0){
            f.setDate(f.getDate() - 1);
        }
        for (let k = 0; k < dias; k++){
            fechas[k] = new Date(f.getFullYear(), f.getMonth(), f.getDate());;
            f.setDate(f.getDate() + 1);
        }
        //forceUpdate();
    }
    function mesSiguiente(){
        let fec = new Date(fecha.getFullYear(), fecha.getMonth(), 28);
        fec.setDate(fec.getDate() + 5);
        fecha = new Date(fec.getFullYear(), fec.getMonth(), 1);
        setFecha(fecha);
        setMes(fecha.getMonth());
        setAnio(fecha.getFullYear());
        cargar();
    }
    function mesAnterior(){
        let fec = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        fec.setDate(fec.getDate() - 5);
        fecha = new Date(fec.getFullYear(), fec.getMonth(), 1);
        setFecha(fecha);
        setMes(fecha.getMonth());
        setAnio(fecha.getFullYear());
        cargar();
    }
    function cambiarMes(m){
        fecha = new Date(fecha.getFullYear(), m, 1);
        setFecha(fecha);
        cargar();
        setMes(m);
        setNivel(1);
    }
    function cambiarAnio(a){
        anio = a;
        fecha = new Date(a, fecha.getMonth(), 1);
        setFecha(fecha);
        cargar();
        setAnio(a);
        setNivel(1);
    }
    function cargarAnios(inicio){
        for(let a = 0; a < 28; a++)
           anios[a] = inicio + a;
    }
    const estilo1 = { display: nivel == 1 ? 'inline' : 'none' };
    const estilo2 = { display: nivel == 2 ? 'inline' : 'none' };
    const estilo3 = { display: nivel == 3 ? 'inline' : 'none' };
    const nomMes = meses[mes];
    return(
        <div className="card bg-gradient-primary">
            <div class="card-header">
                <span className='diaEnc' onClick={()=>mesAnterior()} ><span className="fas fa-backward"/></span>
                <span>&nbsp;</span>
                <b><span onClick={() => setNivel(2)}>{nomMes}</span></b>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <b><span onClick={() => setNivel(3)}>{anio}</span></b>
                <span>&nbsp;</span>
                <span className='diaEnc' onClick={()=>mesSiguiente()} ><span className="fas fa-forward"/></span>
            </div>
            <table className="tbl" style={estilo3}>
                <thead>
                    <tr>
                        <td className='diaEnc' onClick={()=>{cargarAnios(anios[0] - 24); forceUpdate();}} ><span className="fas fa-backward"/></td>
                        <td className='diaEnc'>&nbsp;</td>
                        <td className='diaEnc'>&nbsp;</td>
                        <td className='diaEnc' onClick={()=>{cargarAnios(anios[23] + 1); forceUpdate();}} ><span className="fas fa-forward"/></td>
                    </tr>
                </thead>
                <tbody>
                    {anios.length > 0 && semanas.map((o,s)=>
                        <tr key={s}>
                            {aniosC.map((o,d)=>{
                                let i = s * 4 + d;
                                let a = anios[i];
                                let clase = "card bg-gradient-primary";
                                return ( 
                                    <td className="anio" key={i} onClick={()=> {cambiarAnio(a)}}>
                                        <div className={clase}>
                                            <span>{a}</span>
                                        </div>
                                    </td>
                                )
                            }
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
            <table className="tbl" style={estilo2}>
                <thead>
                    <tr>
                        <td className='diaEnc'>&nbsp;</td>
                        <td className='diaEnc'>&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='mes' onClick={() => cambiarMes(0)}>
                            <span className="card bg-gradient-primary">Enero</span>
                        </td>
                        <td className='mes' onClick={() => cambiarMes(1)}>
                            <span className="card bg-gradient-primary">Febrero</span>
                        </td>
                    </tr>
                    <tr>
                        <td className='mes' onClick={() => cambiarMes(2)}>
                            <span className="card bg-gradient-primary">Marzo</span>
                        </td>
                        <td className='mes' onClick={() => cambiarMes(3)}>
                            <span className="card bg-gradient-primary">Abril</span>
                        </td>
                    </tr>
                    <tr>
                        <td className='mes' onClick={() => cambiarMes(4)}>
                            <span className="card bg-gradient-primary">Mayo</span>
                        </td>
                        <td className='mes' onClick={() => cambiarMes(5)}>
                            <span className="card bg-gradient-primary">Junio</span>
                        </td>
                    </tr>
                    <tr>
                        <td className='mes' onClick={() => cambiarMes(6)}>
                            <span className="card bg-gradient-primary">Julio</span>
                        </td>
                        <td className='mes' onClick={() => cambiarMes(7)}>
                            <span className="card bg-gradient-primary">Agosto</span>
                        </td>
                    </tr>
                    <tr>
                        <td className='mes' onClick={() => cambiarMes(8)}>
                            <span className="card bg-gradient-primary">Septiembre</span>
                        </td>
                        <td className='mes' onClick={() => cambiarMes(9)}>
                            <span className="card bg-gradient-primary">Octubre</span>
                        </td>
                    </tr>
                    <tr>
                        <td className='mes' onClick={() => cambiarMes(10)}>
                            <span className="card bg-gradient-primary">Noviembre</span>
                        </td>
                        <td className='mes' onClick={() => cambiarMes(11)}>
                            <span className="card bg-gradient-primary">Diciembre</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="tbl" style={estilo1}>
                <thead>
                    <tr>
                        <td className='diaEnc'>Do</td>
                        <td className='diaEnc'>Lu</td>
                        <td className='diaEnc'>Ma</td>
                        <td className='diaEnc'>Mi</td>
                        <td className='diaEnc'>Ju</td>
                        <td className='diaEnc'>Vi</td>
                        <td className='diaEnc'>Sa</td>
                    </tr>
                </thead>
                <tbody>
                    {fechas.length > 0 && semanas.map((o,s)=>
                        <tr key={s}>
                            {dias.map((o,d)=>{
                                let pendientes = 0;
                                let i = s * 7 + d;
                                let color = "blanco";
                                let dia = fechas[i].getDate();
                                let clase = fechas[i].getMonth() === fecha.getMonth() ? "card bg-gradient-primary" : "card bg-gradient-secondary";
                                if(fechas[i].getMonth() === fecha.getMonth() && fechas[i].getDate() === fecha.getDate()){
                                    color = "negro";
                                    clase = "card bg-gradient-warning";
                                    pendientes = 15;
                                }
                                if(fechas[i].getMonth() === fechaActual.getMonth() && fechas[i].getDate() === fechaActual.getDate()){
                                    color = "negro";
                                    clase = "card bg-gradient-danger";
                                }
                                return ( 
                                    <td className="dia" key={i} onClick={()=> {setFechaActual(fechas[i]); props.onChange(fechas[i]);}}>
                                        <div className={clase}>
                                            <span className={color}>{dia}
                                            {pendientes == 0 ? null : <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{pendientes}</span>}
                                            </span>
                                        </div>
                                    </td>
                                )
                            }
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}