import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Input, InputGroup, InputGroupAddon, Modal, Button, ModalHeader, ModalBody, Spinner, ModalFooter, Table, Container, Row} from 'reactstrap';
import { Denominacion } from './Denominacion';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { Panel } from './Panel';
import { CalculaIntereses } from './CalculaIntereses';

export const Turno = (props) => {
    const edoGlobal = useContext(AppContext);
    let [editando, setEditando] = useState(false);
    let [clientes, setClientes] = useState([]);
    let [creditos, setCreditos] = useState([]);
    let [formasPago, setFormasPago] = useState([]);
    let [formaPago,setFormaPago] = useState("03");
    let [concepto,setConcepto] = useState("");
    let [tipos, setTipos] = useState([]);
    let [tipo,setTipo] = useState(0);
    let [tipoEs,setTipoES] = useState("");
    let [idCte,setIdCte] = useState(0);
    let [credito,setCredito] = useState("");
    let [inicial, setInicial] = useState(0);
    let [desglose, setDesglose] = useState(0);
    let [denominaciones, setDenominaciones] = useState([]);
    let [caja, setCaja] = useState(0);
    let [turno,setTurno] = useState({Id:0, Abierto : false, Inicial:0, Ingresos:0, Egresos:0, Final:0,Operaciones:0});
    let [cargando,setCargando] = useState(false);
    let [verModalAbrir,setVerModalAbrir] = useState(false);
    let [verModalCalc,setVerModalCalc] = useState(false);
    let [cajas,setCajas] = useState([])
    let [corrida, setCorrida] = useState({Mensualidades:[], MensualidadesMora:[], Pagos:[], TotPerActual:0,TotalMora:0, TotPerActualPag:0,TotalMoraPag:0});
    let [fechaPago,setFechaPago] = useState(new Date().yyyymmdd('-'));
    let [plazoMonto, setPlazoMonto] = useState('P');
    let [parcialidad, setParcialidad] = useState(0);
    let [parcialidades, setParcialidades] = useState([]);
    let [aplicacion, setAplicacion] = useState([]);
    let [pago, setPago] = useState(0);

    useEffect(() => {
        cargarCatalogos();
        if(edoGlobal.usuario.Turno != 0)
           cargarTurno();
    }, []);
    useEffect(() => {
        setCredito("");
        cargarCreditos();
    }, [idCte]);

    useEffect(() => {
        cargarCredito();
    }, [credito]);

    async function cargarCatalogos() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/caja/cajas`;
        let resp = await fetch(url);
        const cajas = await resp.json();
        url = `${edoGlobal.urlws}/credito/clientes`;
        resp = await fetch(url);
        let ctes = await resp.json();
        url = `${edoGlobal.urlws}/caja/tiposMov`;
        resp = await fetch(url);
        let tps = await resp.json();
        url = `${edoGlobal.urlws}/home/formasPago`;
        resp = await fetch(url);
        let formas = await resp.json();
        setCargando(false);
        setClientes(ctes);
        setCajas(cajas);
        setTipos(tps);
        setFormasPago(formas);
    }
    async function cargarCreditos() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/caja/creditosCliente/${idCte}`;
        let resp = await fetch(url);
        const cont = await resp.json();
        setCargando(false);
        setCreditos(cont);
    }
    async function cargarCredito(){
        setCargando(true);
        let i;
        let ini = 0;
        let url = `${edoGlobal.urlws}/credito/cargar?sucursal=${edoGlobal.usuario.Sucursal}&credito=${credito}&fechaPago=${fechaPago}&parcialidad=${parcialidad}&monto=${pago}&plazoMonto=${plazoMonto}`;
        let resp = await fetch(url);
        let res = await resp.json();
        res.TotPerActualPag = res.TotPerActual;
        res.TotalMoraPag = res.TotalMora;
        //setParcialidades(res.Mensualidades.map((o, i) => o.Numero));
        setCargando(false);
        setCorrida(res);
        if(res.MensualidadesMora.length != 0)
           ini = res.MensualidadesMora[res.MensualidadesMora.length - 1].Numero + 1;
        else
           for(i = 0; i < res.Mensualidades.length; i++)
             if(!res.Mensualidades[i].Pagada){
                ini = res.Mensualidades[i].Numero;
                break;
             }
        parcialidades = [];
        for(i = ini; i < res.Mensualidades.length; i++)
           parcialidades.push(i);
        setParcialidad(ini);
        setParcialidades(parcialidades);
    }
    function cambiaTipo(t){
        setTipo(t);
        for (let i = 0; i < tipos.length; i++)
           if(tipos[i].I == t)
              setTipoES(tipos[i].S); 
    }
    function aplicarPago(){
        let imp = 0;
        let st1 = 0;
        let st2 = 0;
        let importe = Number(inicial);
        setPago(inicial);
        corrida.TotPerActualPag = corrida.TotPerActual;
        corrida.TotalMoraPag = corrida.TotalMora - importe;
        aplicacion = corrida.MensualidadesMora.map((o) => {
            return {
                FechaStr : o.FechaStr,
                Capital : o.Capital,
                DiasMora : o.DiasMora,
                InteresMoratorio : o.InteresMoratorio,
                IvaMoratorio : o.IvaMoratorio,
                Interes : o.Interes,
                Iva : o.Iva,
                Total : o.Total,
                Abono : o.Total
            }
        });
        for(let i = 0; i < aplicacion.length; i++){
            aplicacion[i].Abono = 0.0;

            imp = aplicacion[i].InteresMoratorio + aplicacion[i].IvaMoratorio;
            if(importe > imp){
                importe -= imp;
                aplicacion[i].Abono += imp;
                aplicacion[i].InteresMoratorio = 0;                
                aplicacion[i].IvaMoratorio = 0;
            }
            else{
                st1 = importe / 1.16;
                st2 = importe - st1;
                aplicacion[i].Abono += importe;
                aplicacion[i].InteresMoratorio -= st1;
                aplicacion[i].IvaMoratorio -= st2;
                importe = 0;
            }

            imp = aplicacion[i].Interes + aplicacion[i].Iva;
            if(importe > imp){
                importe -= imp;
                aplicacion[i].Abono += imp;
                aplicacion[i].Interes = 0;                
                aplicacion[i].Iva = 0;
            }
            else{
                st1 = importe / 1.16;
                st2 = importe - st1;
                aplicacion[i].Abono += importe;
                aplicacion[i].Interes -= st1;
                aplicacion[i].Iva -= st2;
                importe = 0;
            }
            if(importe > aplicacion[i].Capital){
                importe -= aplicacion[i].Capital;
                aplicacion[i].Abono += aplicacion[i].Capital;
                aplicacion[i].Capital = 0;
            }
            else{
                aplicacion[i].Abono += importe;
                aplicacion[i].Capital -= importe;
                importe = 0;
            }
        }
        corrida.TotPerActualPag = importe;
        /*
        if(importe > corrida.TotPerActualPag){
            importe -= corrida.TotPerActualPag;
            corrida.TotPerActualPag = 0;
        }
        else{
            corrida.TotPerActualPag -= importe;
            importe = 0;
        }
        */
        setAplicacion(aplicacion);
    }
    function nuevaOperacion(){
        setConcepto("");
        setParcialidades([]);
        setAplicacion([]);
        setInicial(0);
        setCredito("");
        setIdCte(0);
        setFormaPago("03");
        setTipo(0);
        setEditando(true);
    }
    function guardarOperacion(){
        if(formaPago === "01" && inicial != desglose){
            Swal.fire('Atencion','El desglose por denominacion es diferente al importe ingresado','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar esta operación ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
               guardarOperacionB();
        });
    }
    async function guardarOperacionB(){
        let dato = {
            FechaPago : fechaPago,
            Turno:turno.Id, Cliente : idCte, TipoMov : tipo, Importe : inicial, 
            Credito : credito, Concepto:concepto, FormaPago : formaPago, Parcialidad: parcialidad,
            m05 : denominaciones[0], m1 : denominaciones[1], m2 : denominaciones[2],
            m5 : denominaciones[3], m10 : denominaciones[4], m20 : denominaciones[5],
            b20 : denominaciones[6], b50 : denominaciones[7], b100 : denominaciones[8],
            b200 : denominaciones[9], b500 : denominaciones[10], b1000 : denominaciones[11],
        }
        let url = `${edoGlobal.urlws}/caja/wsGrabaMov`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,5) !== "Error"){
            cargarTurno();
            setEditando(false);
        }else
           Swal.fire('Atencion',res,'error');
    }
    function totalDenominaciones(tot,dens){
        setDesglose(tot);
        setDenominaciones(dens);
    }
    function importeCalculado(imp){
        setInicial(imp);
    }
    async function cargarTurno(){
        let url = `${edoGlobal.urlws}/caja/turno/${edoGlobal.usuario.Turno}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.json();
        setCargando(false);
        if(res.Status == "ok"){
            setTurno(res.Dato);
        }else
           Swal.fire('Atencion',res.Status,'error');
    }
    function abrirTurno(){
        if(caja == 0){
            Swal.fire('Atencion','Debe indicar la caja','error');
            return;
        }
        if(inicial > desglose){
            Swal.fire('Atencion','El desglose por denominacion es menor al importe ingresado','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea abrir turno ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
               abrirTurnoB();
        });
    }
    async function abrirTurnoB(){
        let dato = {
            Turno:0, Cliente : 0, TipoMov : 0, Importe : inicial, Credito : "", Concepto:"",
            m05 : denominaciones[0], m1 : denominaciones[1], m2 : denominaciones[2],
            m5 : denominaciones[3], m10 : denominaciones[4], m20 : denominaciones[5],
            b20 : denominaciones[6], b50 : denominaciones[7], b100 : denominaciones[8],
            b200 : denominaciones[9], b500 : denominaciones[10], b1000 : denominaciones[11],
        }
        let url = `${edoGlobal.urlws}/caja/abrirTurno?caja=${caja}&cajero=${edoGlobal.usuario.Id}&inicial=${inicial}`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.json();
        console.log("res",res);
        setCargando(false);
        if(res.Status == "ok"){
            edoGlobal.usuario.Turno = res.Dato.Id;
            setTurno(res.Dato);
            setVerModalAbrir(false);
        }else
           Swal.fire('Atencion',res.Status,'error');
    }
    function cerrarTurno(){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cerrar el turno ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
               cerrarTurnoB();
        });
    }
    async function cerrarTurnoB(){
        let url = `${edoGlobal.urlws}/caja/cerrarTurno/${turno.Id}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if(res == "ok"){
            edoGlobal.usuario.Turno = 0;
            setTurno({Id:0, Abierto : false, Inicial:0, Ingresos:0, Egresos:0, Final:0,Operaciones:0});
        }else
           Swal.fire('Atencion',res.Status,'error');
    }
    const estilo1 = { display:turno.Abierto ? 'inline' : 'none' };
    const estilo2 = { display:editando ? 'inline' : 'none' };
    let guardar = idCte != 0 && formaPago != "" && tipo != 0 && credito != "" && inicial != 0;
    return(
        <div>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }

        {!turno.Abierto && <Button size="sm" color="success" outline onClick={()=>setVerModalAbrir(true)}>Abrir turno</Button>}
            <div style={estilo1}>
                <div className='row'>
                    <div className='col-2'>
                        <div className="info-box">
                            <span className="info-box-icon bg-info"><i class="fa fa-cube"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">Inicial</span>
                                <span className="info-box-number">{turno.Inicial.toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="info-box">
                            <span className="info-box-icon bg-info"><i class="fa fa-plus"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">Ingresos</span>
                                <span className="info-box-number verde">{turno.Ingresos.toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="info-box">
                            <span className="info-box-icon bg-info"><i class="fa fa-minus"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">Egresos</span>
                                <span className="info-box-number rojo">{turno.Egresos.toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="info-box">
                            <span className="info-box-icon bg-info"><i class="fa fa-cubes"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">Saldo</span>
                                <span className="info-box-number">{turno.Final.toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="info-box">
                            <span className="info-box-icon bg-info"><i class="fa fa-calculator"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">Operaciones</span>
                                <span className="info-box-number">{turno.Operaciones.toFixed(2)}</span>
                            </div>
                        </div>
                        <br/>
                        {turno.Abierto && <Button size="sm" color="danger" outline onClick={cerrarTurno}>cerrar turno</Button>}
                    </div>
                    <div className='col'>
                        {!editando && <Button size="sm" color="primary" outline  onClick={nuevaOperacion}><i class="fa fa-plus"></i> Nueva operación</Button>}
                        <div style={estilo2}>
                        <Panel titulo="nueva operación">
                            <Container>
                                <Row>
                                    <div className='col-4'>
                                        <span>Cliente</span>
                                        <Input size="sm" type="select" className="form-control" value={idCte} onChange={(e) => setIdCte(e.target.value)}>
                                            <option value={0}>Especifique</option>
                                            {clientes.map((o, i) => (
                                                <option key={i} value={o.I}>
                                                    {o.N}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                        <div className='col-2'>
                                            <span>Fecha pago</span>
                                            <Input size="sm" type="date" value={fechaPago} onChange={(e) => setFechaPago(e.target.value)} />
                                        </div>
                                    <div className='col-2'>
                                        <span>Crédito</span>
                                        <Input size="sm" type="select" className="form-control" value={credito} onChange={(e) => setCredito(e.target.value)}>
                                            <option value={""}>Especifique</option>
                                            {creditos.map((o, i) => (
                                                <option key={i} value={o.Id}>
                                                    {o.Id}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className='col-2'>
                                        <span>Tipo de Operación</span>
                                        <Input size="sm" type="select" className="form-control" value={tipo} onChange={(e) => cambiaTipo(e.target.value)}>
                                            <option value={0}>Especifique</option>
                                            {tipos.map((o, i) => (
                                                <option key={i} value={o.I}>
                                                    {o.N}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className='col-2'>
                                        <span>Forma de pago</span>
                                        <Input size="sm" type="select" className="form-control" value={formaPago} onChange={(e) => setFormaPago(e.target.value)}>
                                            <option value={""}>Especifique</option>
                                            {formasPago.map((o, i) => (
                                                <option key={i} value={o.Id}>
                                                    {o.Nombre}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                </Row>
                                {tipoEs == "I" && <div>
                                <Row>
                                    <div className='col-2'>
                                        <span>Importe</span>
                                        <InputGroup>
                                        <Input size="sm" value={inicial} onChange={(e) => setInicial(e.target.value)} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" outline color="primary" title="aplicar" onClick={aplicarPago}>
                                            <i class="fa fa-calculator" aria-hidden="true"></i>
                                            </Button>
                                        </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    <div className='col-2'>
                                        <span>Desglose</span>
                                        <Input size="sm" value={desglose} />
                                    </div>
                                    <div className='col'>
                                        <span>Concepto</span>
                                        <Input size="sm" value={concepto} onChange={(e) => setConcepto(e.target.value)} />
                                    </div>
                                </Row>
                                        <Row>
                                            <div className="col">
                                                <h6>Intereses moratorios</h6>
                                                <Table size="sm" striped hover>
                                                    <thead>
                                                        <th >No.</th>
                                                        <th className='celdaMed'>Fecha</th>
                                                        <th className='numeroCeldaMed'>Capital</th>
                                                        <th className='numeroCeldaMed'>Dias</th>
                                                        <th className='numeroCeldaMed'>Int.Moratorio</th>
                                                        <th className='numeroCeldaMed'>Int.Ordinario</th>
                                                        <th className='numeroCeldaMed'>Total</th>
                                                        <th></th>
                                                    </thead>
                                                    <tbody>
                                                        {corrida.MensualidadesMora.map((o, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{o.Numero}</td>
                                                                    <td>{o.FechaStr}</td>
                                                                    <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                                                    <td className='numeroCeldaMed'>{o.DiasMora}</td>
                                                                    <td className='numeroCeldaMed'>{(o.InteresMoratorio + o.IvaMoratorio).formato(2)}</td>
                                                                    <td className='numeroCeldaMed'>{(o.Interes + o.Iva).formato(2)}</td>
                                                                    <td className='numeroCeldaMed'>{o.Total.formato(2)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td colspan="5">Intereses ordinarios del periodo corriente</td>
                                                            <td className='numeroCeldaMed'>{corrida.TotPerActual.formato(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5"></td>
                                                            <td className='numeroCeldaMed'>{corrida.TotalMora.formato(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="col">
                                                <h6>aplicación</h6>
                                                <Table size="sm" striped hover>
                                                    <thead>
                                                        <th className='celdaMed'>Fecha</th>
                                                        <th className='numeroCeldaMed'>Capital</th>
                                                        <th className='numeroCeldaMed'>Dias</th>
                                                        <th className='numeroCeldaMed'>Int.Moratorio</th>
                                                        <th className='numeroCeldaMed'>Int.Ordinario</th>
                                                        <th className='numeroCeldaMed'>Total</th>
                                                        <th className='numeroCeldaMed'>Aplicado</th>
                                                        <th></th>
                                                    </thead>
                                                    <tbody>
                                                        {aplicacion.map((o, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{o.FechaStr}</td>
                                                                    <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                                                    <td className='numeroCeldaMed'>{o.DiasMora}</td>
                                                                    <td className='numeroCeldaMed'>{(o.InteresMoratorio + o.IvaMoratorio).formato(2)}</td>
                                                                    <td className='numeroCeldaMed'>{(o.Interes + o.Iva).formato(2)}</td>
                                                                    <td className='numeroCeldaMed'>{o.Total.formato(2)}</td>
                                                                    <td className='numeroCeldaMed'>{o.Abono.formato(2)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td colspan="5">Excedente aplicado a monto en la parcialidad <b>{parcialidad}</b> </td>
                                                            {/*
                                                            <td >
                                                                <Input size="sm" type="select" className="form-control" value={parcialidad} onChange={(e) => setParcialidad(e.target.value)}>
                                                                    <option value={0}>No.</option>
                                                                    {parcialidades.map((o, i) => (
                                                                        <option key={i} value={o}>
                                                                            {o}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </td>
                                                                    */}
                                                            <td className='numeroCeldaMed'>{corrida.TotPerActualPag.formato(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                    <br/>
                                                    <Button size="sm" color="success" outline onClick={guardarOperacion}>Registrar pago</Button>
                                                </Table>
                                            </div>
                                        </Row>
                                <Row>
                                    <div className='col-6'>
                                       {formaPago === "01" && <Denominacion id={turno.Id} actualizaTotales={totalDenominaciones} />}
                                    </div>
                                </Row>
                                </div>}
                                <br/>
                                <Row>
                                    <div className="col-2">                                        
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <Button size="sm" color="danger" outline onClick={()=>setEditando(false)}>Cancelar</Button>
                                    </div>
                                    <div className="col">
                                        {verModalCalc && <CalculaIntereses actualiza={importeCalculado}/>}
                                    </div>
                                </Row>
                            </Container>
                        </Panel>
                        </div>
                    </div>
                </div>
            </div>
        <Modal size="lg" isOpen={verModalAbrir} toggle={() => setVerModalAbrir(false)}>
            <ModalHeader>
                <span>Abrir turno</span>
            </ModalHeader>
            <ModalBody>
                    <div className='row'>
                        <div className='col-3'>
                            <span>Caja</span>
                            <Input type="select" size="sm" value={caja} onChange={(e) => setCaja(e.target.value)}>
                                <option value={0}>Especifique</option>
                                {cajas.map((o, i) => <option value={o.I} key={i}>{o.N}</option>)}
                            </Input>
                        </div>
                        <div className='col-2'>
                            <span>Fondo inicial</span>
                            <Input size="sm" value={inicial} onChange={(e)=>setInicial(e.target.value)} />
                        </div>
                        <div className='col-2'>
                            <span>Desglose</span>
                            <Input size="sm" value={desglose} />
                        </div>
                    </div>
                <Denominacion id={turno.Id} actualizaTotales={totalDenominaciones} />
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="success" outline onClick={abrirTurno}>Aceptar</Button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Button size="sm" color="danger" outline onClick={() => setVerModalAbrir(false)}>Cancelar</Button>
            </ModalFooter>
        </Modal>
        </div>
    )
}
