import React from 'react';
import {useState, useContext, useEffect} from 'react';
import Calendar from 'react-calendar/dist/umd/Calendar';
import { Container, Spinner, Input, Table, Button, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2';
import { Calendario } from './Calendario';

export const Agenda = () => {
    const edoGlobal = useContext(AppContext);
    let [supervisados,setSupervisados] = useState([]);
    let [fecha,setFecha] = useState(new Date());
    let [agenda,setAgenda] = useState([]);
    let [horas,setHoras] = useState([]);
    let [clientes,setClientes] = useState([]);
    let [tipos,setTipos] = useState([]);
    let [cargando,setCargando] = useState(false);
    let [modalDet,setModalDet] = useState(false);
    let [reprogramada,setReprogramada] = useState(false);
    let [realizada,setRealizada] = useState(false);
    let [hora,setHora] = useState("");
    let [texto,setTexto] = useState("");
    let [resultado,setResultado] = useState("");
    let [ejecutivo,setEjecutivo] = useState(0);
    let [tipo,setTipo] = useState(0);
    let [idCte,setIdCte] = useState(0);
    let [actual,setActual] = useState({Id : 0});

    useEffect(() => {
        cargarCatalogos();
    }, []);

    useEffect(() => {
        (async () => {
            cargarAgenda();
        })();
    }, [fecha]);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/agenda/horas`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setHoras(datos);
        url = `${edoGlobal.urlws}/agenda/clientes`;
        resp = await fetch(url);
        datos = await resp.json();
        setClientes(datos);
        url = `${edoGlobal.urlws}/agenda/tipos`;
        resp = await fetch(url);
        datos = await resp.json();
        setTipos(datos);
        url = `${edoGlobal.urlws}/home/supervisados/${edoGlobal.usuario.Id}`;
        resp = await fetch(url);
        datos = await resp.json();
        setSupervisados(datos);
        setCargando(false);
    }

    async function cargarAgenda() {
        setCargando(true);
        let fec = fecha.yyyymmdd('-');
        let url = `${edoGlobal.urlws}/agenda/agenda?ejecutivo=${edoGlobal.usuario.Id}&fecIni=${fec}&fecFin=${fec}`;
        let resp = await fetch(url);
        const datos = await resp.json();
        setCargando(false);
        setAgenda(datos);
    }
    async function cargar(id) {
        setCargando(true);
        let fec = fecha.yyyymmdd('-');
        let url = `${edoGlobal.urlws}/agenda/cargar/${id}`;
        let resp = await fetch(url);
        const o = await resp.json();
        setCargando(false);
        setActual(o);
        setEjecutivo(o.Ejecutivo == 0 ? edoGlobal.usuario.Id : o.Ejecutivo);
        setTipo(o.Tipo);
        setHora(o.Hora);
        setIdCte(o.Cliente);
        setRealizada(o.Realizada);
        setReprogramada(o.Reprogramada);
        setTexto(o.Texto);
        setResultado(o.Resultado);
        setModalDet(true);
    }
    function editar(o){
        cargar(o.Id);
    }
    function nueva(){
        cargar(0);
    }
    async function guardar(){
        if(tipo < 0){
            Swal.fire('Atencion','No se ha indicado el tipo','error');
            return;
        }
        if(idCte < 0){
            Swal.fire('Atencion','No se ha indicado el cliente','error');
            return;
        }
        const dato = {
            Id : actual.Id, //edoGlobal.usuario.Almacen,
            Sucursal : 1, Usuario : edoGlobal.usuario.Id,
            Fecha : fecha.yyyymmdd('-'), Hora : hora,
            Cliente: idCte, Tipo: tipo, Ejecutivo : ejecutivo,
            Realizada: realizada, Reprogramada : reprogramada, 
            Texto:texto, Resultado : resultado,
            IdAnt : actual.IdAnt, IdSig : actual.IdSig
        }
        let url = `${edoGlobal.urlws}/agenda/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            setModalDet(false);
            cargarAgenda()
        }
        else{
            Swal.fire('Atencion',res,'error');
        }
    }
    return(
        <Row>
            <div className='col-2'>
                <Calendario onChange={setFecha} />
                <div className="info-box">
                    <span className="info-box-icon bg-info"><i class="far fa-envelope"></i></span>
                    <div className="info-box-content">
                        <span className="info-box-text">Emails</span>
                        <span className="info-box-number">14</span>
                    </div>
                </div>
                <div className="info-box">
                    <span className="info-box-icon bg-info"><i class="fa fa-phone"></i></span>
                    <div className="info-box-content">
                        <span className="info-box-text">Llamadas</span>
                        <span className="info-box-number">3</span>
                    </div>
                </div>
            </div>
            <i class="fa fa-plus-circle"
                aria-hidden="true"
                title="Nueva"
                onClick={nueva}></i>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                {/* The time line */}
                                <div className="timeline">
                                    {/* timeline time label */}
                                    <br />
                                    {agenda.map((o, i) => {
                                        let claseIcono = o.Tipo == 1 ? "fa fa-phone" : "fas fa-envelope bg-blue";
                                        return (
                                            <>
                                                <div className="time-label">
                                                    <span className="bg-red"><i class="fas fa-clock"></i> {fecha.yyyymmdd("-")}</span>
                                                </div>
                                                {/* /.timeline-label */}
                                                {/* timeline item */}
                                                <div>
                                                    <i className={claseIcono} />
                                                    <div className="timeline-item">
                                                        <span className="time">
                                                            <i className="fas fa-clock" /> {o.Hora}
                                                        </span>
                                                        <h3 className="timeline-header">
                                                            <a href="#">{o.NomEjecutivo}</a> {o.NomTipo} <spam>para </spam> <a href="#">{o.NomCliente}</a>
                                                        </h3>
                                                        <div className="timeline-body flex-timeline">
                                                            <div><p className="parrafo">{o.Texto}</p></div>
                                                            <div> <table>
                                                                <tr>
                                                                    <td> <p>Realizada |</p></td>
                                                                    <td><p>Reprogramada </p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="input-check-center"><input type="checkbox" checked={o.Realizada} /></td>
                                                                    <td class="input-check-center"><input type="checkbox" checked={o.Reprogramada} /></td>
                                                                </tr>
                                                            </table>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                            <Button color="secondary" outline size="sm" onClick={() => editar(o)}>
                                                                Ver más
                                                            </Button>
                                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                            <Button color="danger" outline size="sm" >
                                                                Eliminar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </div>
                                            </>
                                        );
                                    })}
                                    {/* END timeline item */}
                                    {/* timeline item */}

                                    {/* END timeline item */}
                                    <div>
                                        <i className="fas fa-clock bg-gray" />

                                    </div>    </div></div></div>
                    </div>
                </section>
            <Modal size="lg" isOpen={modalDet} toggle={() => setModalDet(false)}>
                <ModalBody>
                    <Container>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-primary">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    {actual.Id == 0 ? (
                                                        <span>Registrar Nueva Tarea</span>
                                                    ) : (
                                                        <span>Editando</span>
                                                    )}

                                                </h3>
                                            </div>
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <br />
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox">
                                                                <Input
                                                                    class="form-check-input"
                                                                    id="check-rea"
                                                                    type="checkbox"
                                                                    checked={realizada}
                                                                    onChange={(e) =>
                                                                        setRealizada(e.target.checked)
                                                                    }
                                                                />
                                                                <label
                                                                    for="check-rea"
                                                                    class="form-check-label"
                                                                >
                                                                    Realizada
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        {/* radio */}
                                                        <br />
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox">
                                                                <Input
                                                                    type="checkbox"
                                                                    id="check-repro"
                                                                    checked={reprogramada}
                                                                    onChange={(e) =>
                                                                        setReprogramada(e.target.checked)
                                                                    }
                                                                />
                                                                <label
                                                                    for="check-repro"
                                                                    class="form-check-label"
                                                                >
                                                                    Reprogramada
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        {/* select */}
                                                        <div className="form-group">
                                                            <label>Hora</label>
                                                            <Input size="sm"
                                                                type="select"
                                                                className="form-control"
                                                                value={hora}
                                                                onChange={(e) => setHora(e.target.value)}
                                                            >
                                                                <option value={""}>Especifique</option>{" "}
                                                                {horas.map((o, i) => (
                                                                    <option key={i} value={o.Id}>
                                                                        {" "}
                                                                        {o.Id}{" "}
                                                                    </option>
                                                                ))}{" "}
                                                            </Input>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Ejecutivo</label>
                                                            <Input size="sm"
                                                                type="select"
                                                                className="form-control"
                                                                value={ejecutivo}
                                                                onChange={(e) => setEjecutivo(e.target.value)}
                                                            >
                                                                <option value={0}>Especifique</option>
                                                                {supervisados.map((o, i) => (
                                                                    <option key={i} value={o.Id}>
                                                                        {o.Nombre}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        {/* select */}
                                                        <div className="form-group">
                                                            <label>Tipo</label>
                                                            <Input size="sm" type="select"
                                                                className="form-control"
                                                                value={tipo}
                                                                onChange={(e) => setTipo(e.target.value)}
                                                            >
                                                                <option value={0}>Especifique</option>
                                                                {tipos.map((o, i) => (
                                                                    <option key={i} value={o.I}>
                                                                        {o.N}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        {/* select */}
                                                        <div className="form-group">
                                                            <label>Cliente</label>
                                                            <Input size="sm" type="select"
                                                                className="form-control"
                                                                value={idCte}
                                                                onChange={(e) => setIdCte(e.target.value)}
                                                            >
                                                                <option value={0}>Especifique</option>
                                                                {clientes.map((o, i) => (
                                                                    <option key={i} value={o.I}>
                                                                        {o.N}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label>Comentarios</label>
                                                <Input
                                                    style={{ height: 150 }}
                                                    type="textarea"
                                                    class="form-control"
                                                    rows="3"
                                                    value={texto}
                                                    onChange={(e) => setTexto(e.target.value)}
                                                />
                                                <label>Resultado</label>
                                                <Input
                                                    style={{ height: 150 }}
                                                    type="textarea"
                                                    class="form-control"
                                                    rows="3"
                                                    value={resultado}
                                                    onChange={(e) => setResultado(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <div className="col-2">
                        <Button color="primary" size="sm" onClick={guardar}>
                            Aceptar
                        </Button>
                    </div>
                    <div className="col-2">
                        <Button color="danger" size="sm" onClick={() => setModalDet(false)}>
                            Cancelar
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </Row>
    )
}