import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Container, Spinner, Input, Table, Button, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { AppContext } from './AppContext';
import { Tabla } from './Tabla';
import { CalculaIntereses } from './CalculaIntereses';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

export const EstadosCuenta = (props) => {
    const edoGlobal = useContext(AppContext);
    let [estados, setEstados] = useState([]);
    let [regimenes, setRegimenes] = useState([]);
    let [usos, setUsos] = useState([]);
    let [formas, setFormas] = useState([]);
    let [anios, setAnios] = useState([]);
    let [meses, setMeses] = useState([]);
    let [anio,setAnio] = useState("");
    let [mesIni, setMesIni] = useState("");
    let [mesFin, setMesFin] = useState("");
    const [cargando, setCargando] = useState(false);
    let [idEdo, setIdEdo] = useState(0);
    let [nombre, setNombre] = useState("");
    let [rfc, setRfc] = useState("");
    let [cp, setCP] = useState("");
    let [email, setEmail] = useState("");
    let [regimen, setRegimen] = useState("");
    let [forma, setForma] = useState("");
    let [uso, setUso] = useState("");
    let [verModalCte, setVerModalCte] = useState(false);
    let [cant, setCant] = useState(0);
    let [nombreFiltro, setNombreFiltro] = useState("");
    let [timbradoFiltro, setTimbradoFiltro] = useState("X");
    let [verModalCalc,setVerModalCal] = useState(false);
    
    useEffect(() => {
        var f = new Date().yyyymmdd('-');
        setAnio(f.substring(0,4));
        setMesIni(f.substring(5,7));
        setMesFin(f.substring(5,7));
        cargarCatalogos();
    }, []);

    async function cargarCatalogos() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/home/formasPago`;
        let resp = await fetch(url);
        const f = await resp.json();
        url = `${edoGlobal.urlws}/home/regimenes`;
        resp = await fetch(url);
        const r = await resp.json();
        url = `${edoGlobal.urlws}/home/usos`;
        resp = await fetch(url);
        const u = await resp.json();
        url = `${edoGlobal.urlws}/home/anios`;
        resp = await fetch(url);
        const a = await resp.json();
        url = `${edoGlobal.urlws}/home/meses`;
        resp = await fetch(url);
        const m = await resp.json();
        setCargando(false);
        setFormas(f);
        setRegimenes(r);
        setUsos(u);
        setAnios(a);
        setMeses(m);
    }

    async function cargarObjetos() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/home/estados?anio=${anio}&perIni=${mesIni}&perFin=${mesFin}&nombre=${nombreFiltro}&timbrado=${timbradoFiltro}`;
        let resp = await fetch(url);
        const datos = await resp.json();
        setCargando(false);
        setEstados(datos);
        setCant(datos.length);
    }
    async function subir(){
        const { value: file } = await Swal.fire({
            title: 'Procesar archivo CSV',
            input: 'file',
            inputAttributes: {
              accept: 'csv/*',
              'aria-label': 'Seleccione archivo CSV'
            }
          });
          
          if (file) {
            setCargando(true);
            const data = new FormData()
            data.append('file', file);
            data.append('id', 0);    
            let url = `${edoGlobal.urlws}/home/subirArchivo`;
            const resp = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            setCargando(false);
            Swal.fire('Atencion',res,'success');    
            cargarObjetos();
          }        
    }
    async function subirPLD(){
        const { value: file } = await Swal.fire({
            title: 'Procesar archivo CSV',
            input: 'file',
            inputAttributes: {
              accept: 'csv/*',
              'aria-label': 'Seleccione archivo CSV'
            }
          });
          
          if (file) {
            setCargando(true);
            const data = new FormData()
            data.append('file', file);
            data.append('id', 0);    
            let url = `${edoGlobal.urlws}/home/subirArchivoPLD`;
            const resp = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            setCargando(false);
            if(res.substring(0,2) == "Er")
                Swal.fire('Atencion',res,'error');
            else{
                url = `${edoGlobal.urlws}/content/pld/${res}`;
                window.open(url, '', '');        
            }    
          }        
    }
    async function timbrarTodo(){
        if(estados.length == 0){
            Swal.fire('Atencion','No hay estados de cuenta por timbrar','info');    
            return;
        }
        let errores = 0;
        let correctos = 0;
        let url = "";
        for (let i = 0; i < estados.length; i++) 
            if(!estados[i].Timbrado){
                url = `${edoGlobal.urlws}/home/timbrar/${estados[i].Id}`;
                setCargando(true); 
                let resp = await fetch(url);
                let res = await resp.text();
                if(res.substring(0,2) !== "Er"){
                    estados[i].Timbrado = true;
                    correctos++;
                }
                else
                   errores++;
                estados[i].Uuid = res;
                setCargando(false); 
            }
        Swal.fire('Atencion','Timbrados ' + correctos + ' // errores : ' + errores ,'success');
    }
    function retimbrar(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "estado de cuenta previamente cancelado, ¿ desea timbrarlo de nuevo ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(result.value)
                 timbrar(i);
          })        
    }
    function timbrar(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ timbrar el estado de cuenta de " + estados[i].Nombre +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(!result.value)
                 return;
              let url = `${edoGlobal.urlws}/home/timbrar/${estados[i].Id}`;
              setCargando(true); 
              let resp = await fetch(url);
              let res = await resp.text();
              if(res.substring(0,2) !== "Er")
                 estados[i].Timbrado = true;
              estados[i].Uuid = res;
              setCargando(false); 
              if(res.substring(0,2) == "Er")
                 Swal.fire('Atencion',res,'error');;
          })        
     }
     async function actualizarStatus(i){
        let url = `${edoGlobal.urlws}/home/actualizarStatus/${estados[i].Id}`;
        setCargando(true); 
        let resp = await fetch(url);
        let res = await resp.text();
        setCargando(false); 
        if(res == "ok"){
            Swal.fire('Atencion','CFDI Cancelado','info');
            cargarObjetos();
        }
        else
           Swal.fire('Atencion',res,'error');
     }
     function cancelar(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ cancelar el estado de cuenta de " + estados[i].Nombre +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(!result.value)
                 return;
              let url = `${edoGlobal.urlws}/home/cancelar/${estados[i].Id}?motivo=02`;
              setCargando(true); 
              let resp = await fetch(url);
              let res = await resp.text();
              setCargando(false); 
              if(res.substring(0,2) == "Er")
                 Swal.fire('Atencion',res,'error');
              else{
                Swal.fire('Atencion',res,'success');
                cargarObjetos();
              }
          })        
     }
     function guardarCte(){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarCteB();
        });
    }
    async function guardarCteB(){
        let dato = {
            Id:idEdo, Nombre : nombre, RFC : rfc, CP : cp, Email : email,
            UsoCfdi : uso, Regimen : regimen, FormaPago : forma
        }
        let url = `${edoGlobal.urlws}/home/editar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            cargarObjetos();
            setVerModalCte(false);
        }else
           Swal.fire('Atencion',res,'error');
    }
    async function editar(i){
        const url = `${edoGlobal.urlws}/home/datosCliente/${estados[i].Id}`;
        setCargando(true);
        const req = await fetch(url);
        const res = await req.json();
        if(res.Id == 0)
           return;
        setIdEdo(estados[i].Id);
        setNombre(res.Nombre);
        setRfc(res.RFC);
        setCP(res.CP);
        setEmail(res.Email);
        setRegimen(res.Regimen);
        setForma(res.FormaPago);
        setUso(res.UsoCfdi)
        setCargando(false);
        setVerModalCte(true);
     }
     function zip(){
        const url = `${edoGlobal.urlws}/home/zip/${anio}${mesIni}`;
        window.open(url, '', '');
    }
    function resumen(){
        const url = `${edoGlobal.urlws}/home/resumen/${anio}${mesIni}`;
        window.open(url, '', '');
    }
    function imprimir(i){
        const url = `${edoGlobal.urlws}/home/pdf/${estados[i].Id}`;
        window.open(url, '', '');
    }
    function imprimirPoliza(pol){
        const url = `https://contaweb.isaerpweb.com/reportes/poliza?s=1-2024-${pol}`;
        window.open(url, '', '');
    }
    function circuloCredito(){
        const url = `${edoGlobal.urlws}/home/circuloCreditoCsv/1`;
        window.open(url, '', '');
    }
    async function enviarEmailAviso(i){
        const {value: emailCap} = await Swal.fire({
            title: 'Enviar aviso de privacidad por email',
            input: 'email',
            inputValue: estados[i].Email,
            inputPlaceholder: 'Direccion de email'
          })
          
          if (emailCap) {
            enviarEmailAvisoB(i,emailCap);
          }
    }
    async function enviarEmailAvisoB(i,email) {
        const url = `${edoGlobal.urlws}/home/emailAvisoPrivacidad/${estados[i].Id}?email=${email}`;
        setCargando(true);
        const req = await fetch(url, {
            method: 'POST' // or 'PUT'
          });
        const res = await req.text();
        if(res !== "ok"){
            setCargando(false);
            Swal.fire('Atencion',res,'error');
            return res;
        }
        estados[i].Enviado = true;
        setCargando(false);
        Swal.fire('Atencion','Se envio el email a ' + email,'success');
        return "ok";
    }
    async function emailAvisos(){
        if(estados.length == 0){
            Swal.fire('Atencion','No hay estados de cuenta','info');    
            return;
        }
        let errores = 0;
        let correctos = 0;
        let url = "";
        let email = "";
        let enviados = [];
        let enviar = false;
        setCargando(true); 
        for (let i = 0; i < estados.length; i++) {
            estados[i].Txt = "";
        }
        setCargando(false); 
        for (let i = 0; i < estados.length; i++) {
            enviar = true;
            for (let j = 0; j < enviados.length; j++)
               if(enviados[j] == estados[i].Cliente){
                   enviar = false;
                   break;
               }
            if(enviar){
                email = estados[i].Email;
                if(email != "" && !estados[i].Privacidad){
                    url = `${edoGlobal.urlws}/home/emailAvisoPrivacidad/${estados[i].Id}?email=${email}`;
                    setCargando(true); 
                    const req = await fetch(url, {
                        method: 'POST' // or 'PUT'
                      });
                    const res = await req.text();
                    if(res.substring(0,2) == "ok"){
                        correctos++;
                    }
                    else
                       errores++;
                    estados[i].Txt = res;
                    setCargando(false); 
                    enviados.push(estados[i].Cliente);
                }
            }
        }
        Swal.fire('Atencion','Enviados ' + correctos + ' // errores : ' + errores ,'success');
    }

    async function emailTodo(){
        if(estados.length == 0){
            Swal.fire('Atencion','No hay estados de cuenta por enviar','info');    
            return;
        }
        let errores = 0;
        let correctos = 0;
        let url = "";
        let email = "";
        for (let k = 0; k < estados.length; k++){
            setCargando(true); 
            estados[k].Enviado = false;
            setCargando(false); 
        }
        for (let i = 0; i < estados.length; i++) {
            email = estados[i].Email;
            if(email != "" && estados[i].Timbrado && estados[i].Status != "C"){
                url = `${edoGlobal.urlws}/home/email/${estados[i].Id}?email=${email}`;
                setCargando(true); 
                const req = await fetch(url, {
                    method: 'POST' // or 'PUT'
                  });
                const res = await req.text();
                if(res.substring(0,2) == "ok"){
                    estados[i].Enviado = true;
                    correctos++;
                }
                else
                   errores++;
                setCargando(false); 
            }
        }
        Swal.fire('Atencion','Enviados ' + correctos + ' // errores : ' + errores ,'success');
    }
    async function generarPolizas(){
        if(estados.length == 0){
            Swal.fire('Atencion','No hay estados de cuenta por contabilizar','info');    
            return;
        }
        for (let k = 0; k < estados.length; k++){
            setCargando(true); 
            estados[k].Enviado = false;
            setCargando(false); 
        }
        let errores = 0;
        let correctos = 0;
        let url = "";
        for (let i = 0; i < estados.length; i++) {
            if(estados[i].Timbrado && estados[i].Status != "C"){
                url = `${edoGlobal.urlws}/conta/generarPolizaEstado/${estados[i].Id}`;
                setCargando(true);
                const req = await fetch(url, {
                    method: 'POST' // or 'PUT'
                  });
                const res = await req.text();  
                if(res.substring(0,2) == "Er")
                    errores++
                else{
                    estados[i].Enviado = true;
                    correctos++;
                }
                setCargando(false); 
            }
        }
        Swal.fire('Atencion','se generaron ' + correctos + ' polizas','success');
    }
    async function generarPoliza(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ desea generar las polizas contables de este pago ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(!result.value)
                return;
              const url = `${edoGlobal.urlws}/conta/generarPolizaEstado/${estados[i].Id}`;
              setCargando(true);
              const req = await fetch(url, {
                  method: 'POST' // or 'PUT'
                });
              const res = await req.text();
              setCargando(false);
              if(res.substring(0,2) == "Er"){
                  Swal.fire('Atencion',res,'error');
                  return res;
              }
              else
                  mostrarPoliza(i,res)
          })        
    }
    async function mostrarPoliza(i,res){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'polizas generadas con exito',
            text: "¿ desea ver las polizas generadas ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(!result.value)
                return;
              imprimirPoliza(res+"-D-" + estados[i].Id);
              imprimirPoliza(res+"-I-" + estados[i].Id);
      })        
    }
    async function enviarEmail(i){
        const {value: emailCap} = await Swal.fire({
            title: 'Enviar estado de cuenta por email',
            input: 'email',
            inputValue: estados[i].Email,
            inputPlaceholder: 'Direccion de email'
          })
          
          if (emailCap) {
            enviarEmailB(i,emailCap);
          }
    }
    async function enviarEmailB(i,email) {
        const url = `${edoGlobal.urlws}/home/email/${estados[i].Id}?email=${email}`;
        setCargando(true);
        const req = await fetch(url, {
            method: 'POST' // or 'PUT'
          });
        const res = await req.text();
        if(res !== "ok"){
            setCargando(false);
            Swal.fire('Atencion',res,'error');
            return res;
        }
        estados[i].Enviado = true;
        setCargando(false);
        Swal.fire('Atencion','Se envio el email a ' + email,'success');
        return "ok";
    }
    return (
        <div>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }
                <Row>
                    <table>
                        <tr>
                            <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Del año</span></td>
                            <td>
                                <Input size="sm" type="select" value={anio} onChange={e => setAnio(e.target.value)}>
                                    {anios.map((o,i)=><option key={i} value={o.Id}>{o.Nombre}</option>)}
                                </Input>
                            </td>
                            <td><span>Del mes</span></td>
                            <td>
                                <Input size="sm" type="select" value={mesIni} onChange={e => setMesIni(e.target.value)}>
                                    {meses.map((o,i)=><option key={i} value={o.Id}>{o.Nombre}</option>)}
                                </Input>
                            </td>
                            <td><span>al mes</span></td>
                            <td>
                                <Input size="sm" type="select" value={mesFin} onChange={e => setMesFin(e.target.value)}>
                                    {meses.map((o,i)=><option key={i} value={o.Id}>{o.Nombre}</option>)}
                                </Input>
                            </td>
                            <td><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                            <td>
                            <div className="form-inline sidebar-search-open">
                                <div className="input-group" data-widget="sidebar-search">
                                    <input className="form-control form-control-sm form-control-navbar" type="search" placeholder="nombre" aria-label="Search" size="sm" value={nombreFiltro} onChange={e => setNombreFiltro(e.target.value)} />
                                    <div className="input-group-append">
                                        <button className="btn btn-sm btn-sidebar btn-outline-secondary" onClick={cargarObjetos}>
                                            <i className="fas fa-search fa-fw" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </td>
                            <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Timbrado&nbsp;&nbsp;</span></td>
                            <td>
                                <Input size="sm" type="select" value={timbradoFiltro} onChange={e => setTimbradoFiltro(e.target.value)}>
                                    <option value="X">Todos</option>
                                    <option value="S">Timbrados</option>
                                    <option value="N">Sin timbrar</option>
                                </Input>
                            </td>
                            <td><span>&nbsp;</span><Button outline color="success" size="sm" onClick={cargarObjetos}><img src="imagenes/ok.png" alt="cargar" /></Button></td>
							<td><span className="badge badge-size-lg badge-success my-badge"><li className="far fa-clone"/> {cant} registros</span></td>
                        </tr>
                    </table>
                </Row>
                <Row>
                    <table>
                        <tr>
                            <td>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                {props.timbrar ? <Button outline color="success" size="sm" onClick={subir} ><img src="imagenes/explorar.png" alt="procesar archivo" />Procesar archivo</Button> : null }
                            </td>
                            <td>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                {props.timbrar && cant > 0 ? <Button outline color="warning" size="sm" onClick={timbrarTodo} ><img src="imagenes/xml.png" alt="timbrar todos" />Timbrar todos</Button> : null }
                                <span>&nbsp;&nbsp;</span>
                                {props.timbrar && cant > 0 ? <Button outline color="success" size="sm" onClick={emailTodo} ><img src="imagenes/email.png" alt="enviar todos" />Enviar todos</Button> : null }
                                <span>&nbsp;&nbsp;</span>
                                {props.timbrar ? <Button outline size="sm" onClick={zip} title="descargar en zip"><img src="imagenes/zip.png" alt="descargar en zip" />Descargar</Button> : null }
                                <span>&nbsp;&nbsp;</span>
                                {props.timbrar ? <Button outline size="sm" onClick={resumen} title="resumen del mes">Resumen</Button> : null }
                                <span>&nbsp;&nbsp;</span>
                                {props.timbrar ? <Button outline size="sm" onClick={circuloCredito} title="circulo de credito"><img src="imagenes/tabla.png" alt="circulo de credito" />Circulo de credito</Button> : null }
                                <span>&nbsp;&nbsp;</span>
                                {props.timbrar && cant > 10000 ? <Button outline color="success" size="sm" onClick={emailAvisos} ><img src="imagenes/email.png" alt="enviar avisos" /> Enviar avisos de privacidad</Button> : null }
                                {props.timbrar && cant > 0 ? <Button outline color="success" size="sm" onClick={generarPolizas} >Generar polizas</Button> : null }
                                <Button size="sm" outline color="warning" onClick={()=>setVerModalCal(true)}><img src="imagenes/cotizar.png" alt="calcular" /></Button>
                            </td>
                            <td>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button outline color="success" size="sm" onClick={subirPLD} ><img src="imagenes/explorar.png" alt="procesar archivo PLD" />Generar avisos PLD</Button>
                            </td>
                        </tr>
                    </table>
                </Row>
            <Table size="sm" striped hover>
                <thead>
                    <th className="celdaCh"></th>
                    <th className='celdaMed'>Credito</th>
                    <th className='celdaNom'>Nombre</th>
                    <th className='celdaMed'>Periodo</th>
                    <th className='numeroCeldaMed'>Total</th>
                    <th className='celdaMed'>Fecha Pago</th>
                    <th className='numeroCeldaMed'>Pago</th>
                    <th className='numeroCeldaMed'>Factura</th>
                    <th className="celdaCh">Timbrado</th>
                    <th className="celdaCh">Enviado</th>
                    <th className="celdaCh"></th>
                    <th></th>
                    <th>Status</th>
                    <th className="celdaCh"></th>
                    <th></th>
                </thead>
                <tbody>
                    {estados.map((o, i) => {
                        return (
                            <tr key={i}>
                                <td className="celdaCh">
                                    {!o.Timbrado && <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={()=>editar(i)} />}
                                </td>
                                <td>{o.Credito}</td>
                                <td>{o.Nombre}</td>
                                <td>{o.Periodo}</td>
                                <td className='numeroCeldaMed'>{o.ImporteTotal.formato(2)}</td>
                                <td>{o.FechaLimite}</td>
                                <td className='numeroCeldaMed'>{o.ImportePago.formato(2)}</td>
                                <td className='numeroCeldaMed'>{o.ImporteFactura.formato(2)}</td>
                                <td className="celdaCh"><input type="checkbox" checked={o.Timbrado} /></td>
                                <td className="celdaCh"><input type="checkbox" checked={o.Enviado} /></td>
                                {
                                    /*
                                <td className="celdaCh">
                                    {
                                        o.Privacidad ? 
                                        <input type="checkbox" checked={o.Privacidad} />
                                        :
                                        <img src="/imagenes/email.png" alt="aviso de privacidad" title="aviso de privacidad" />
                                    }
                                </td>
                                    */
                                }
                                <td className="celdaCh">
                                    {o.Timbrado ?
                                    <div>
                                        <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={()=>imprimir(i)} />
                                        <img src="/imagenes/email.png" alt="enviar" title="enviar" onClick={()=>enviarEmail(i)} />
                                        <img src="/imagenes/tabla.png" alt="generar poliza" title="generar poliza" onClick={()=>generarPoliza(i)} />
                                    </div>
                                        : null
                                    }
                                    {//o.Timbrado && o.Status == "C" && props.timbrar  ?
                                     //   <img src="/imagenes/xml.png" alt="timbrar" title="timbrar" onClick={()=> retimbrar(i)} />
                                     //   : null
                                    }
                                    {!o.Timbrado && props.timbrar  ?
                                        <img src="/imagenes/xml.png" alt="timbrar" title="timbrar" onClick={()=> timbrar(i)} />
                                        : null
                                    }
                                </td>
                                <td>
                                    <span>{o.Uuid}</span>
                                </td>
                                <td>
                                    <span>{o.NomStatus}</span>
                                </td>
                                <td className="celdaCh">
                                    {o.Timbrado && o.Status == "V" && <img src="/imagenes/cross.png" alt="cancelar" title="cancelar" onClick={()=>cancelar(i)} />}
                                    {o.Timbrado && o.Status == "P" && <img src="/imagenes/refresh.png" alt="actualizar status" title="actualizar status" onClick={()=>actualizarStatus(i)} />}
                                </td>
                                <td>{o.Txt}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <Modal size="lg" isOpen={verModalCte} toggle={() => setVerModalCte(false)}>
                <ModalHeader>
                    <h6>Editar datos del cliente</h6>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className="col-7">
                        Nombre<Input size="sm" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </div>
                        <div className="col-3">
                        R.F.C.<Input size="sm" value={rfc} onChange={(e) => setRfc(e.target.value)} />
                        </div>
                        <div className="col-2">
                        C.P.<Input size="sm" value={cp} onChange={(e) => setCP(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className="col">
                            Regimen
                            <Input size="sm" type='select' value={regimen} onChange={(e) => setRegimen(e.target.value)}>
                                {regimenes.map((o, i) => <option key={i} value={o.Id}>{o.Nombre}</option>)}
                            </Input>
                        </div>
                        <div className="col-5">
                        Email<Input size="sm" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className="col">
                            Forma de pago
                            <Input size="sm" type='select' value={forma} onChange={(e) => setForma(e.target.value)}>
                                {formas.map((o, i) => <option key={i} value={o.Id}>{o.Nombre}</option>)}
                            </Input>
                        </div>
                        <div className="col">
                            Uso de Cfdi
                            <Input size="sm" type='select' value={uso} onChange={(e) => setUso(e.target.value)}>
                                {usos.map((o, i) => <option key={i} value={o.Id}>{o.Nombre}</option>)}
                            </Input>
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                        <div className='col-2'></div>
                        <div className='col-8'>
                            <Button size="sm" outline color="success" onClick={guardarCte}>Guardar</Button>
                            &nbsp;&nbsp;
                            <Button size="sm" outline color="danger" onClick={() => setVerModalCte(false)}>Cancelar</Button>
                        </div>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={verModalCalc} toggle={() => setVerModalCal(false)}>
                <CalculaIntereses />
            </Modal>
        </div>
    )
}