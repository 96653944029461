import React from 'react';
import {useState, useEffect, useContext} from 'react';
import { Input, Table, Modal, Button, ModalHeader, ModalBody, Spinner, ModalFooter, Badge, Container, Row} from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { Panel } from './Panel';

export const Corrida = (props) => {
    const edoGlobal = useContext(AppContext);
    let [fecha,setFecha] = useState(new Date().yyyymmdd('-'));
    let [fechaPago,setFechaPago] = useState(new Date().yyyymmdd('-'));
    let [monto, setMonto] = useState(182639.40);
    let [meses, setMeses] = useState(36);
    let [tasa, setTasa] = useState(25);
    let [corrida, setCorrida] = useState({Mensualidades:[], MensualidadesMora:[], TotPerActual:0,TotalMora:0, TotPerActualPag:0,TotalMoraPag:0});
    let [cargando, setCargando] = useState(false);
    let [conMora, setConMora] = useState(false);
    let [conApp, setConApp] = useState(false);
    let [aplicacion, setAplicacion] = useState([]);
    let [pago, setPago] = useState(0);
    let [pagadas, setPagadas] = useState(0);
    let [clientes,setClientes] = useState([]);
    let [cliente, setCliente] = useState(0);
    let [generada, setgenerada] = useState(false);

    useEffect(() => {
        cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/agenda/clientes`;
        let resp = await fetch(url);
        let datos = await resp.json();
        setClientes(datos);
        setCargando(false);
    }
    async function guardar(){
        let url = `${edoGlobal.urlws}/credito/guardar?usuario=${edoGlobal.usuario.Id}&cliente=${cliente}&monto=${monto}&meses=${meses}&tasa=${tasa}&fechaPrimerPago=${fecha}&pagadas=${pagadas}&fechaPago=`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        alert(res);
    }

    async function cargar(){
        let url = `${edoGlobal.urlws}/credito/corrida?monto=${monto}&meses=${meses}&tasa=${tasa}&fechaPrimerPago=${fecha}&pagadas=${pagadas}&fechaPago=`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.json();
        res.TotPerActualPag = res.TotPerActual;
        res.TotalMoraPag = res.TotalMora;
        if(cliente != 0)
           setgenerada(true);
        setConApp(false);
        setConMora(false);
        setCargando(false);
        setCorrida(res);
    }
    async function cargarMora(){
        let url = `${edoGlobal.urlws}/credito/corrida?monto=${monto}&meses=${meses}&tasa=${tasa}&fechaPrimerPago=${fecha}&fechaPago=${fechaPago}&pagadas=${pagadas}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.json();
        res.TotPerActualPag = res.TotPerActual;
        res.TotalMoraPag = res.TotalMora;
        setConApp(false);
        setConMora(true);
        setCargando(false);
        setCorrida(res);
    }
    function aplicar(){
        let importe = Number(pago);
        corrida.TotPerActualPag = corrida.TotPerActual;
        corrida.TotalMoraPag = corrida.TotalMora - importe;
        aplicacion = corrida.MensualidadesMora.map((o) => {
            return {
                FechaStr : o.FechaStr,
                Capital : o.Capital,
                DiasMora : o.DiasMora,
                InteresMoratorio : o.InteresMoratorio,
                IvaMoratorio : o.IvaMoratorio,
                Interes : o.Interes,
                Iva : o.Iva,
                Total : o.Total,
                Abono : o.Total
            }
        });
        for(let i = 0; i < aplicacion.length; i++){
            aplicacion[i].Abono = 0.0;
            if(importe > aplicacion[i].InteresMoratorio){
                importe -= aplicacion[i].InteresMoratorio;
                aplicacion[i].Abono += aplicacion[i].InteresMoratorio;
                aplicacion[i].InteresMoratorio = 0;
            }
            else{
                aplicacion[i].Abono += importe;
                aplicacion[i].InteresMoratorio -= importe;
                importe = 0;
            }
            if(importe > aplicacion[i].IvaMoratorio){
                importe -= aplicacion[i].IvaMoratorio;
                aplicacion[i].Abono += aplicacion[i].IvaMoratorio;
                aplicacion[i].IvaMoratorio = 0;
            }
            else{
                aplicacion[i].Abono += importe;
                aplicacion[i].IvaMoratorio -= importe;
                importe = 0;
            }
            if(importe > aplicacion[i].Interes){
                importe -= aplicacion[i].Interes;
                aplicacion[i].Abono += aplicacion[i].Interes;
                aplicacion[i].Interes = 0;
            }
            else{
                aplicacion[i].Abono += importe;
                aplicacion[i].Interes -= importe;
                importe = 0;
            }
            if(importe > aplicacion[i].Iva){
                importe -= aplicacion[i].Iva;
                aplicacion[i].Abono += aplicacion[i].Iva;
                aplicacion[i].Iva = 0;
            }
            else{
                aplicacion[i].Abono += importe;
                aplicacion[i].Iva -= importe;
                importe = 0;
            }
            if(importe > aplicacion[i].Capital){
                importe -= aplicacion[i].Capital;
                aplicacion[i].Abono += aplicacion[i].Capital;
                aplicacion[i].Capital = 0;
            }
            else{
                aplicacion[i].Abono += importe;
                aplicacion[i].Capital -= importe;
                importe = 0;
            }
        }
        if(importe > corrida.TotPerActualPag){
            importe -= corrida.TotPerActualPag;
            corrida.TotPerActualPag = 0;
        }
        else{
            corrida.TotPerActualPag -= importe;
            importe = 0;
        }
        setAplicacion(aplicacion);
        setConApp(true);
    }
    const estilo = { display: conMora ? 'inline' : 'none' };
    const estiloApp = { display: conApp ? 'inline' : 'none' };
    return(
        <div>
            <Container>
                {cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="warning" />
                    </div>
                    :
                    null
                }
                <Row>
                    <div className='col'>
                    <span>Cliente</span>
                    <Input size="sm" type="select" className="form-control" value={cliente} onChange={(e) => setCliente(e.target.value)}>
                        <option value={0}>Especifique</option>
                        {clientes.map((o, i) => (
                            <option key={i} value={o.I}>
                                {o.N}
                            </option>
                        ))}
                    </Input>
                    </div>
                </Row>
                <Row>
                    <div className='col-2'>
                        <span>Monto a financiar</span>
                        <Input size="sm" value={monto} onChange={(e) => setMonto(e.target.value)} />
                    </div>
                    <div className='col-1'>
                        <span>Plazo</span>
                        <Input size="sm" value={meses} onChange={(e) => setMeses(e.target.value)} />
                    </div>
                    <div className='col-1'>
                        <span>Tasa</span>
                        <Input size="sm" value={tasa} onChange={(e) => setTasa(e.target.value)} />
                    </div>
                    <div className='col-2'>
                        <span>Primer pago</span>
                        <Input size="sm" type="date" value={fecha} onChange={(e) => setFecha(e.target.value)} />
                    </div>
                    <div className='col-1'>
                        <span>Pagadas</span>
                        <Input size="sm" value={pagadas} onChange={(e) => setPagadas(e.target.value)} />
                    </div>
                    <div className='col'>
                        <br/>
                        <Button size="sm" color="primary" onClick={cargar} > <span className="info-box-icon bg-info"><i class="fa fa-calculator"></i></span> Tabla amortización</Button>
                        <span>&nbsp;&nbsp;</span>
                        {generada && <Button size="sm" color="primary" onClick={guardar} > Guardar </Button>}
                    </div>
                </Row>
                <Row>
                    <div className='col-2'>
                        <span>Fecha pago</span>
                        <Input size="sm" type="date" value={fechaPago} onChange={(e) => setFechaPago(e.target.value)} />
                    </div>
                    <div className='col-2'>
                        <br/>
                        <Button size="sm" color="primary" onClick={cargarMora} > <span className="info-box-icon bg-info"><i class="fa fa-calculator"></i></span> Mora</Button>
                    </div>
                </Row>
                <Row>
                    <div className='col'>
                        <hr/>
                        <h6>Tabla de amortización</h6>
                        <Table size="sm" striped hover>
                            <thead>
                                <th className="celdaCh">No.</th>
                                <th className='celdaMed'>Fecha</th>
                                <th className='numeroCeldaMed'>Capital</th>
                                <th className='numeroCeldaMed'>Amortizacion</th>
                                <th className='numeroCeldaMed'>Intereses</th>
                                <th className='numeroCeldaMed'>I.V.A.</th>
                                <th className='numeroCeldaMed'>Pago</th>
                                <th className='numeroCeldaMed'>Pagada</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {corrida.Mensualidades.map((o, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{o.Numero}</td>
                                            <td>{o.FechaStr}</td>
                                            <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Amortizacion.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Interes.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Iva.formato(2)}</td>
                                            <td className='numeroCeldaMed'>{o.Pago.formato(2)}</td>
                                            <td><input type="checkbox" checked={o.Pagada} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className='col'>
                        <div style={estilo}>
                            <hr />
                            <h6>Intereses moratorios</h6>
                            <Table size="sm" striped hover>
                                <thead>
                                    <th className='celdaMed'>Fecha</th>
                                    <th className='numeroCeldaMed'>Capital</th>
                                    <th className='numeroCeldaMed'>Dias</th>
                                    <th className='numeroCeldaMed'>Int.Moratorio</th>
                                    <th className='numeroCeldaMed'>Int.Ordinario</th>
                                    <th className='numeroCeldaMed'>Total</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {corrida.MensualidadesMora.map((o, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{o.FechaStr}</td>
                                                <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                                <td className='numeroCeldaMed'>{o.DiasMora}</td>
                                                <td className='numeroCeldaMed'>{(o.InteresMoratorio + o.IvaMoratorio).formato(2)}</td>
                                                <td className='numeroCeldaMed'>{(o.Interes + o.Iva).formato(2)}</td>
                                                <td className='numeroCeldaMed'>{o.Total.formato(2)}</td>
                                            </tr>
                                        )
                                    })}
                                        <tr>
                                            <td colspan="5">Intereses ordinarios del periodo corriente</td>
                                            <td className='numeroCeldaMed'>{corrida.TotPerActual.formato(2)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td className='numeroCeldaMed'>{corrida.TotalMora.formato(2)}</td>
                                        </tr>
                                </tbody>
                            </Table>
                            <Row>
                                <div className='col-2'>
                                    <span>Pago</span>
                                    <Input size="sm" value={pago} onChange={(e) => setPago(e.target.value)} />
                                </div>
                                <div className='col-2'>
                                    <br />
                                    <Button size="sm" color="primary" onClick={aplicar} > <span className="info-box-icon bg-info"><i class="fa fa-calculator"></i></span> Aplicar</Button>
                                </div>
                            </Row>
                            <div style={estiloApp}>
                                <hr />
                                <h6>aplicación</h6>
                                <Table size="sm" striped hover>
                                    <thead>
                                        <th className='celdaMed'>Fecha</th>
                                        <th className='numeroCeldaMed'>Capital</th>
                                        <th className='numeroCeldaMed'>Dias</th>
                                        <th className='numeroCeldaMed'>Int.Moratorio</th>
                                        <th className='numeroCeldaMed'>Int.Ordinario</th>
                                        <th className='numeroCeldaMed'>Total</th>
                                        <th className='numeroCeldaMed'>Aplicado</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        {aplicacion.map((o, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{o.FechaStr}</td>
                                                    <td className='numeroCeldaMed'>{o.Capital.formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{o.DiasMora}</td>
                                                    <td className='numeroCeldaMed'>{(o.InteresMoratorio + o.IvaMoratorio).formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{(o.Interes + o.Iva).formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{o.Total.formato(2)}</td>
                                                    <td className='numeroCeldaMed'>{o.Abono.formato(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colspan="5">Intereses ordinarios del periodo corriente</td>
                                            <td className='numeroCeldaMed'>{corrida.TotPerActualPag.formato(2)}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td className='numeroCeldaMed'>{corrida.TotalMoraPag.formato(2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}
